import React from 'react';

export const TextArea = props => {
  return (
    <textarea
      name={props.name}
      placeholder={props.placeholder}
      onChange={props.onChange}
      className={`${props.className || ''} textarea-wrapper`}
      title={props.title}
    />
  );
};
