import React from 'react';

import { Link } from 'gatsby';

export default props => (
  <footer className='apollo-footer'>
    <div className='footer-1'>
      <div className='footer-logo'>
        <a className='apollo-logo-white' href='/#home'>
          <svg>
            <use xlinkHref='/sprite.svg#apollo-new-logo-icon' />
          </svg>
        </a>
      </div>
      <div className='footer-1-content'>
        <div className='footer-1-1'>
          <div className='footer-menu-container footer-links-mobile'>
            <nav className='footer-menu'>
              <a href='/about#'>About Us</a>
              <a href='/insurance'>Insurance</a>
              <a href='/medicare'>Medicare</a>
            </nav>
            <nav className='footer-menu footer-menu-right'>
              <a href='/careers'>Careers</a>
              <a
                onClick={() => {
                  props.setLicensedInOpened && props.setLicensedInOpened(!props.licensedInOpened);
                }}>
                Licenced In
              </a>
            </nav>
          </div>

          <div className='footer-menu-container'>
            <div className=''>
              <div className='footer-section-title'>Company</div>
              <nav className='footer-half-menu'>
                <a href='/about/#what-makes-different'>What Makes Apollo Different?</a>
                <a href='/about/#leadership'>Leadership</a>
                <a href='/careers#'>Careers</a>
                <a className='privacy-label' href='/privacy-policy'>
                  Privacy Policy
                </a>
              </nav>
            </div>

            <div className=''>
              <div className='footer-section-title'>Insurance</div>
              <nav className='footer-half-menu'>
                <a href='/insurance/life'>Life Insurance</a>
                <a href='/insurance/group'>Group Insurance</a>
                <a href='/medicare'>Medicare</a>
              </nav>
            </div>
          </div>
          <div className='footer-connect-with-us-container footer-connect-with-us-container-web'>
            <div className='footer-connect-with-us'>Connect With Us:</div>
            <div className='footer-connect-with-us-icons'>
              <a rel='noreferrer' target='_blank' href='https://www.facebook.com/ApolloInsuranceAdvisors/'>
                <svg>
                  <use xlinkHref='/sprite.svg#facebook-circle-icon' />
                </svg>
              </a>
              <a rel='noreferrer' target='_blank' href='https://www.instagram.com/p/CLmWLu0pJ2Z/'>
                <svg>
                  <use xlinkHref='/sprite.svg#instagram-circle-icon' />
                </svg>
              </a>
              <a rel='noreferrer' target='_blank' href='https://www.linkedin.com/company/apollo-insurance-group/'>
                <svg>
                  <use xlinkHref='/sprite.svg#linkedin-circle-icon' />
                </svg>
              </a>
            </div>
          </div>
        </div>

        <div className='footer-1-2'>
          <div className=''>
            <div className='footer-menu-container footer-menu-container-licensed-in'>
              <div className=''>
                <div className='footer-section-title'>Licensed in</div>
                <nav className='footer-half-menu'>
                  <a href='/licensed-in/alabama#'>Alabama</a>
                  <a href='/licensed-in/arizona#'>Arizona</a>
                  <a href='/licensed-in/arkansas#'>Arkansas</a>
                  <a href='/licensed-in/colorado#'>Colorado</a>
                  <a href='/licensed-in/florida#'>Florida</a>
                  <a href='/licensed-in/illinois#'>Illinois</a>
                  <a href='/licensed-in/indiana#'>Indiana</a>
                  <a href='/licensed-in/iowa#'>Iowa</a>
                  <a href='/licensed-in/kansas#'>Kansas</a>
                  <a href='/licensed-in/kentucky#'>Kentucky</a>
                  <a href='/licensed-in/michigan#'>Michigan</a>
                  <a href='/licensed-in/mississippi#'>Mississippi</a>
                  <a href='/licensed-in/missouri#'>Missouri</a>
                  <a href='/licensed-in/nebraska#'>Nebraska</a>
                </nav>
              </div>

              <div className=''>
                <div className='footer-section-title'>
                  <br />
                </div>
                <nav className='footer-half-menu'>
                  <a href='/licensed-in/nevada#'>Nevada</a>
                  <a href='/licensed-in/new-york#'>New York</a>
                  <a href='/licensed-in/north-carolina#'>North Carolina</a>
                  <a href='/licensed-in/ohio#'>Ohio</a>
                  <a href='/licensed-in/oklahoma#'>Oklahoma</a>
                  <a href='/licensed-in/careers#'>Pennsylvania</a>
                  <a href='/licensed-in/south-carolina#'>South Carolina</a>
                  <a href='/licensed-in/tennessee#'>Tennessee</a>
                  <a href='/licensed-in/texas#'>Texas</a>
                  <a href='/licensed-in/utah#'>Utah</a>
                  <a href='/licensed-in/virginia#'>Virginia</a>
                  <a href='/licensed-in/washington#'>Washington</a>
                  <a href='/licensed-in/wisconsin#'>Wisconsin</a>
                  <a href='/licensed-in/wyoming#'>Wyoming</a>
                </nav>
              </div>
            </div>
          </div>
          <div className='footer-connect-with-us-container footer-connect-with-us-container-mobile'>
            <div className='footer-connect-with-us'>Connect With Us:</div>
            <div className='footer-connect-with-us-icons'>
              <a rel='noreferrer' target='_blank' href='https://www.facebook.com/ApolloInsuranceAdvisors/'>
                <svg>
                  <use xlinkHref='/sprite.svg#facebook-circle-icon' />
                </svg>
              </a>
              <a rel='noreferrer' target='_blank' href='https://www.instagram.com/p/CLmWLu0pJ2Z/'>
                <svg>
                  <use xlinkHref='/sprite.svg#instagram-circle-icon' />
                </svg>
              </a>
              <a rel='noreferrer' target='_blank' href='https://www.linkedin.com/company/apollo-insurance-group/'>
                <svg>
                  <use xlinkHref='/sprite.svg#linkedin-circle-icon' />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className='footer-2'>
      Copyright © 2021 Apollo Insurance. <br />
      All Rights Reserved. 5251 W 116TH PL, Suite 200 Leawood KS 66211
    </div>
  </footer>
);
