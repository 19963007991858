import React from 'react';
import { Input } from '../inputs/input';

export const ChildForm = props => (
  <div className='phone-lead__form___child_input'>
    <Input type='text' name={`custom_Child ${props.childNumber} Name`} placeholder={`Child ${props.childNumber} Name`} />
    <Input
      type='text'
      name={`custom_Child ${props.childNumber} Date of Birth`}
      placeholder={`Child ${props.childNumber} Date Of Birth - MM/DD/YYYY`}
      title='MM/DD/YYYY'
      pattern='(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d'
    />
  </div>
);
