import React from 'react';

export const Input = props => {
  return (
    <input
      type={props.type}
      name={props.name}
      id={props.id}
      size={props.size}
      placeholder={props.placeholder}
      onChange={props.onChange}
      className={`input-wrapper ${props.className || ''}`}
      pattern={props.pattern}
      title={props.title}
    />
  );
};
