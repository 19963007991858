import React from 'react';
import Footer from '../footer';
import Header from '../header';
import GetAQuote from '../get-a-quote-form';

const Pages = props => <div>{props.children}</div>;

const Layout = ({ hasGetAQuoteButton = true, ...props }) => {
  return (
    <div id='root' className={`root ${props.getAQuoteOpened ? 'root-without-scroll' : ''}`}>
      {hasGetAQuoteButton && (
        <div style={{ cursor: 'pointer' }} className='quote-tag'>
          <div onClick={() => props.setGetAQuoteOpened(true)} className={`get-a-quote ${props.getAQuoteOpened ? 'get-a-quote-opened' : ''}`}>
            GET A QUOTE
          </div>
        </div>
      )}
      <div id={props.id} />
      <Header {...props} />
      <GetAQuote {...props} />
      <Pages>{props.children}</Pages>
      <Footer {...props} />
    </div>
  );
};

export default Layout;
