import React, { useState } from 'react';
import { ChildForm } from './child.form';
import Layout from '../../components/layout';
import { Input } from '../inputs/input';
import { TextArea } from '../inputs/textarea';

export const PhoneLeadForm = () => {
  const [currentTab, setCurrentTab] = useState();
  const [licensedInOpened, setLicensedInOpened] = useState(false);
  const [getAQuoteOpened, setGetAQuoteOpened] = useState(false);

  const props = { currentTab, setCurrentTab, licensedInOpened, setLicensedInOpened, getAQuoteOpened, setGetAQuoteOpened };

  return (
    <div className='phone-lead-root'>
      <Layout {...props} hasGetAQuoteButton={false}>
        <div className='phone-lead'>
          <form autoComplete='off' action='https://app.agencybloc.com/fp/webToLead/v1/3PYXNWQWH0EGBGWADO7W/' method='post'>
            <div className='phone-lead__form'>
              <div className='phone-lead__form___lead_informations'>
                <label className='phone-lead__form___lead_informations_label'>Lead Information</label>
                <select name='custom_Phone Lead Source'>
                  <option value='' disabled selected>
                    Phone Lead Source
                  </option>
                  <option value='Aged Benepath'>Aged Benepath</option>
                  <option value='Aliera Web Lead'>Aliera Web Lead</option>
                  <option value='AllWebLeads'>AllWebLeads</option>
                  <option value='Apollo Web Lead'>Apollo Web Lead</option>
                  <option value='BCBS Overflow'>BCBS Overflow</option>
                  <option value='Benepath-API'>Benepath-API</option>
                  <option value='Billboard'>Billboard</option>
                  <option value='BNI'>BNI</option>
                  <option value='Chamber of Commerce'>Chamber of Commerce</option>
                  <option value='Cigna Dental'>Cigna Dental</option>
                  <option value='Cigna Health'>Cigna Health</option>
                  <option value='Cigna Health CO'>Cigna Health CO</option>
                  <option value='Cigna Health FL'>Cigna Health FL</option>
                  <option value='Cigna Health IL'>Cigna Health IL</option>
                  <option value='Cigna Health KS'>Cigna Health KS</option>
                  <option value='Cigna Health OOM'>Cigna Health OOM</option>
                  <option value='Cigna Health VA'>Cigna Health VA</option>
                  <option value='Cigna MO'>Cigna MO</option>
                  <option value='Email Blast'>Email Blast</option>
                  <option value='Farm Bureau referral'>Farm Bureau referral</option>
                  <option value='Lead Crusher'>Lead Crusher</option>
                  <option value='Lead Hustler'>Lead Hustler</option>
                  <option value='Liberty Tax'>Liberty Tax</option>
                  <option value='Marketplace Lead'>Marketplace Lead</option>
                  <option value='Newsletter'>Newsletter</option>
                  <option value='Precise Data Leads'>Precise Data Leads</option>
                  <option value='Precise Warm Transfer'>Precise Warm Transfer</option>
                  <option value='Recruiting - Career Fair'>Recruiting - Career Fair</option>
                  <option value='Recruiting - Hand Shake'>Recruiting - Hand Shake</option>
                  <option value='Ref USA'>Ref USA</option>
                  <option value='Referral'>Referral</option>
                  <option value='Rick Krapes'>Rick Krapes</option>
                  <option value='Scott Referral'>Scott Referral</option>
                  <option value='SVG'>SVG</option>
                  <option value='Zip Quote'>Zip Quote</option>
                </select>
                <Input
                  type='text'
                  placeholder='Phone Lead Date - MM/DD/YYYY'
                  name='leadDate'
                  title='MM/DD/YYYY'
                  pattern='(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d'
                />
              </div>
              <div className='phone-lead__form___personal_informations'>
                <label className='phone-lead__form___personal_informations_label'>People Information</label>
                <Input type='text' name='firstName' placeholder='First Name' />
                <Input type='text' name='lastName' placeholder='Last Name' />
                <Input
                  type='text'
                  name='birthDate'
                  placeholder='Date Of Birth'
                  title='MM/DD/YYYY'
                  pattern='(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d'
                />
                <Input type='text' name='custom_Spouse First Name' placeholder='Spouse First Name' />
                <Input type='text' name='custom_Spouse Last Name' placeholder='Spouse Last Name' />
                <Input
                  type='text'
                  name='custom_Spouse Date of Birth'
                  placeholder='Spouse Date Of Birth'
                  title='MM/DD/YYYY'
                  pattern='(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d'
                />
              </div>
              <div className='phone-lead__form___child_informations'>
                <label className='phone-lead__form___child_informations_label'>Child Information</label>
                <ChildForm childNumber='1' />
                <ChildForm childNumber='2' />
                <ChildForm childNumber='3' />
                <ChildForm childNumber='4' />
              </div>
              <div className='phone-lead__form___contact_informations'>
                <label className='phone-lead__form___contact_informations_label'>Contact Information</label>
                <Input type='tel' name='homePhone' placeholder='(555) 555-1234' data-mask='(___) ___-____' />
                <Input type='text' name='email' placeholder='email@email.com' />
              </div>
              <div className='phone-lead__form___address_informations'>
                <label className='phone-lead__form___address_informations_label'>Address Information</label>
                <Input type='text' name='zip' placeholder='Zip' />
                <Input type='text' name='street1' placeholder='Street 1' />
                <Input type='text' name='city' placeholder='City' />
                <Input type='text' name='stateAbbrev' placeholder='State Abreviation' />
              </div>

              <div className='phone-lead__form___budget_informations'>
                <label className='phone-lead__form___budget_informations_label'>Budget Information</label>
                <Input type='text' name='custom_Income' placeholder='Income' />
                <Input type='text' name='custom_Conditions/Prescriptions?' placeholder='Conditions/Prescriptions' className='bigger' />
                <Input type='text' name='custom_Phone Lead Budget' placeholder='Phone Lead Budget' />
              </div>

              <div className='phone-lead__form___financial_informations'>
                <label className='phone-lead__form___financial_informations_label'>Financial Information</label>
                <Input type='text' name='custom_Bank Name' placeholder='Bank Name' />
                <Input type='text' name='custom_Routing' placeholder='Routing' />
                <Input type='text' name='custom_Bank Account' placeholder='Bank Account' />
                <Input type='text' name='custom_SSN' placeholder='SSN' />
              </div>
              <div className='phone-lead__form___final_informations'>
                <label className='phone-lead__form___final_informations_label'>Final Information</label>
                <Input
                  type='text'
                  name='custom_Doctors/Hospitals'
                  placeholder='Doctors/Hospitals'
                  className='phone-lead__form___final_informations_input_doctor'
                />
                <TextArea name='custom_Comments' placeholder='Comments' className='phone-lead__form___final_informations_input_comment' />
                <select name='servicingAgentID' className='bigger'>
                  <option value='' selected disabled>
                    Phone Agent
                  </option>
                  <option value='946863'>Tom Albers</option>
                  <option value='2848686'>Tanner Arst</option>
                  <option value='2252556'>Austyn Bair</option>
                  <option value='2528325'>Carter Barajas</option>
                  <option value='2848687'>Christian Bickley</option>
                  <option value='1676918'>Jared Boyett</option>
                  <option value='2252559'>Oliver Byers</option>
                  <option value='2570369'>Jacob Dwight Cooper</option>
                  <option value='2252561'>Matt Davis</option>
                  <option value='2825103'>Colin Doerr</option>
                  <option value='2825110'>Ray Dorsey</option>
                  <option value='2085981'>Sherry Dunwoody</option>
                  <option value='946867'>Jordan Eckley</option>
                  <option value='954995'>Luke Eckley</option>
                  <option value='946204'>Scott Eckley</option>
                  <option value='2825111'>Hunter Falls</option>
                  <option value='2825109'>Patrick Foster</option>
                  <option value='2825105'>Dane Freberg</option>
                  <option value='950076'>Liz Friedrichsen</option>
                  <option value='2560747'>Cynthia C Garduno Martinez</option>
                  <option value='2252560'>Zach Gensky</option>
                  <option value='1747760'>Derek Grayson</option>
                  <option value='946861'>Jeff Gueldner</option>
                  <option value='946864'>Brian Hofbauer</option>
                  <option value='1565756'>Marq Hughes</option>
                  <option value='2825104'>Will Hughes</option>
                  <option value='949526'>Troy Jefferson</option>
                  <option value='2825106'>Alex Karrenbrock</option>
                  <option value='946860'>Phil Kathol</option>
                  <option value='2825102'>Jacob Katzfey</option>
                  <option value='949525'>James King</option>
                  <option value='2252557'>Brendan Lay</option>
                  <option value='2150585'>Adam Ledbetter</option>
                  <option value='2825114'>Andrew Loberg</option>
                  <option value='2252554'>Jack MacGowan</option>
                  <option value='2832798'>Joe Mailander</option>
                  <option value='2351681'>Jonah Meleski</option>
                  <option value='2825113'>Miguel Morales</option>
                  <option value='950288'>Kyle Nord</option>
                  <option value='946866'>Mike Occhipinto</option>
                  <option value='2236025'>Matt Peebles</option>
                  <option value='3072877'>Robin Pelaccio</option>
                  <option value='2819037'>Lindsay Pickering</option>
                  <option value='2819036'>Natalie Robertaccio</option>
                  <option value='1125544'>Rich Robertaccio</option>
                  <option value='1535714'>Matt Safranek</option>
                  <option value='2825107'>Adam Siplon</option>
                  <option value='2825108'>Matt Sisk</option>
                  <option value='2825112'>Sienna Spiglanin</option>
                  <option value='946865'>Ted Stealey</option>
                  <option value='3100452'>Jacob Summers</option>
                  <option value='2347788'>Austin Zimmerman</option>
                  <option value='2252555'>Isaac Zitterkopf</option>
                  <option value='2771809'>Mike Zulkowski</option>
                  <option value='3103747'>Fredy Arce</option>
                  <option value='3103744'>Julio Diaz</option>
                  <option value='3103745'>Eric Sarmiento</option>
                  <option value='1980856'>Juan Urzua</option>
                  <option value='3111186'>Gracen Gueldner</option>
                  <option value='3114815'>Ivonne Mota</option>
                  <option value='2214227'>Thelma Arevelo</option>
                  <option value='2871872'>Emma Zudor</option>
                  <option value='3078883'>Christine Smith</option>
                  <option value='950076'>Liz Friedrichsen</option>
                </select>
              </div>
              <button className='phone-lead__form___submit-button' type='submit'>
                Submit
              </button>
            </div>
          </form>
        </div>
      </Layout>
    </div>
  );
};
