import React from 'react';
import { Input } from '../inputs/input';

export default props => {
  return (
    <div className={`${props.getAQuoteOpened ? 'get-a-quote-opened' : 'get-a-quote-closed'}`}>
      <div className='get-a-quote-form'>
        <div onClick={() => props.setGetAQuoteOpened && props.setGetAQuoteOpened(!props.getAQuoteOpened)} className={'x-close'}>
          X
        </div>
        {/* <p>GET A FREE QUOTE</p>
        <p>
          ANY INFORMATION YOU PROVIDE TO APOLLO INSURANCE WILL NOT BE SHARED WITH ANY OTHER GROUPS. WE VALUE RELATIONSHIPS WITH OUR CLIENTS IN THE HIGHEST
          REGARD. SHOULD YOU USE THIS REQUEST FORM, YOU WILL BE CONTACTED BY ONLY ONE APOLLO AGENT, UNLESS YOU REQUEST OTHERWISE. THANK YOU FOR ENTRUSTING
          APOLLO TO SERVICE YOUR HEALTH COVERAGE NEEDS.
        </p> */}
        <form autoComplete='off' action='https://app.agencybloc.com/fp/webToLead/v1/0ADCB64ANNWKYBBT559H/' method='post' className='abLeadForm'>
          <p>
            <label>First Name</label>
            <br />
            <Input type='text' name='firstName' />
          </p>
          <p>
            <label>Last Name</label>
            <br />
            <Input type='text' name='lastName' />
          </p>
          <p>
            <label>Email</label>
            <br />
            <Input type='text' name='email' />
          </p>
          <p>
            <label>Home Phone</label>
            <br />
            <Input type='text' name='homePhone' />
          </p>
          <p>
            <label>Date of Birth</label>
            <br />
            <Input type='text' name='birthDate' />
          </p>
          <p>
            <label>Gender</label>
            <br />
            <Input type='text' name='gender' />
          </p>
          <p>
            <label>Zip</label>
            <br />
            <Input type='text' name='zip' />
          </p>
          <p>
            <label>Comments</label>
            <br />
            <Input type='text' name='custom_Comments' />
          </p>

          <div className='get-a-quote-disclaimer'>
            WE VALUE RELATIONSHIPS WITH OUR CLIENTS IN THE HIGHEST REGARD CONTACTED BY ONLY ONE APOLLO AGENT.
            <br />
            ANY INFORMATION YOU PROVIDE TO APOLLO INSURANCE WILL NOT BE SHARED WITH ANY OTHER GROUPS.
          </div>
          <p>
            <button className='get-a-quote-submit-button' type='submit'>
              Submit
            </button>
          </p>
        </form>
      </div>
    </div>
  );
};
